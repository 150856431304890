const Support = () => {
  return (
    <div className="center">
      <h3 className="white-text">
        Scan the QR code with any UPI App to support
      </h3>
      <img alt="UPI Sajan Thomas" src="/upi.jpg" />
    </div>
  );
};

export default Support;
